/* Add your customizations of the layout variables here */
$primaryColor: var(--primary-color);
$blackColor: #000000;
$whiteColor: #ffffff;
$shadowColor: #00000029;
$borderColor: #a2a2a2;
$grayTextColor: #505050;
$invalidDatePickerColor: #e24c4c;
$redColor: #d71920;
$textShadowColor: #959595;
$lightGray: #dddddd;
$grayBackgroundColor: #f6f6f6;
$scrollPanelBarColor: #e4e4e4;
$redBackgroundColor: #c43729;
$transparent: transparent;
$headerBackgroundColor: var(--header-background-color);
$footerBackgroundColor: var(--footer-background-color);
