.footer {
  color: $whiteColor;
  background: $footerBackgroundColor;

  .footer-menu-list {
    list-style: none;
    padding: 0;

    li {
      display: inline;
      margin-right: px_to_rem(10);

      a {
        color: $whiteColor;
        text-decoration: none;
        cursor: pointer;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .footer-logo {
    display: block;
    max-width: 200px;

    img {
      max-width: 200px;
    }
  }

  .footer-logos {
    border-top: 1px solid $whiteColor;
    border-bottom: 1px solid $whiteColor;

    img {
      max-width: px_to_rem(90);
      max-height: px_to_rem(50);
    }

    .allsecure-icon {
      img {
        max-width: px_to_rem(150);
      }
    }

    .banca-intesa-icon,
    .mastercard-check-id-icon {
      background-color: $whiteColor;
      display: flex;
      height: px_to_rem(49);
      justify-content: center;
      align-items: center;
      padding: 0 10px 0 10px;

      img {
        max-height: px_to_rem(40);
      }
    }
  }

  @media screen and (max-width: 650px) {
    .footer-menu-list {
      li {
        display: block;
        margin-right: 0;
        margin-bottom: 5px;
      }
    }
    .footer-logos {
      .cards,
      .banks-procesors {
        justify-content: center;
      }
    }
  }
}
