.static-header {
  background: $headerBackgroundColor;
  color: $whiteColor;
  text-transform: uppercase;
  font-weight: bold;
  min-height: 53px;

  .p-button {
    &.p-button-link {
      color: $whiteColor;
      text-decoration: none;

      &:hover,
      &:enabled:hover,
      &:active,
      &:focus {
        color: $whiteColor;
        text-decoration: none;
      }
    }
  }

  img {
    max-width: 100%;
  }
  
  @media screen and (max-width: 575px) {
    .right-logo {
      display: none;
    }
  }
}
